define("account-talentrh/pods/registry/general/user/show/corporate/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    user: Ember.computed.alias('model'),
    ajax: Ember.inject.service(),
    userAccountIsBlocked: null,
    getUserAccountIsBlocked: (0, _emberConcurrency.task)(function* () {
      try {
        const {
          blocked
        } = yield this.ajax.request(`userAccount/getUserAccountIsBlocked/${this.user.cpf}`);
        Ember.set(this, 'userAccountIsBlocked', blocked);
      } catch (e) {
        console.log(e);
      }
    }).drop(),
    userAccountBlockedSituation: Ember.computed('userAccountIsBlocked', function () {
      return this.userAccountIsBlocked;
    }),
    professionalPhone: Ember.computed('user.professionalPhone', function () {
      if (!this.user.professionalPhone) {
        return '';
      }
      return this.user.professionalPhone.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
    }),
    professionalCellphone: Ember.computed('user.professionalCellphone', function () {
      if (!this.user.professionalCellphone) {
        return '';
      }
      return this.user.professionalCellphone.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
    })
  });
  _exports.default = _default;
});