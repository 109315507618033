define("account-talentrh/pods/registry/general/user/controller", ["exports", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let RegistryGeneralUserController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class RegistryGeneralUserController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "accessProfileFilter", _descriptor, this);
      _initializerDefineProperty(this, "departmentFilter", _descriptor2, this);
      _initializerDefineProperty(this, "filters", _descriptor3, this);
      _initializerDefineProperty(this, "jobFunctionFilter", _descriptor4, this);
      _initializerDefineProperty(this, "openFilter", _descriptor5, this);
      _initializerDefineProperty(this, "nameFilter", _descriptor6, this);
      _initializerDefineProperty(this, "statusFilter", _descriptor7, this);
      _initializerDefineProperty(this, "cpfFilter", _descriptor8, this);
      _initializerDefineProperty(this, "jobRoleFilter", _descriptor9, this);
      _defineProperty(this, "statusOptions", [{
        label: 'Ativo',
        value: 1
      }, {
        label: 'Inativo',
        value: 0
      }, {
        label: 'Afastado',
        value: 2
      }, {
        label: 'Bloqueado',
        value: 3
      }]);
    }
    clearFilters() {
      this.accessProfileFilter = null;
      this.departmentFilter = null;
      this.filters = {};
      this.jobFunctionFilter = null;
      this.statusFilter = null;
      this.nameFilter = null;
      this.cpfFilter = null;
      this.jobRoleFilter = null;
    }
    getFilters() {
      let filters = {};
      if (this.accessProfileFilter && this.accessProfileFilter.length) {
        filters.accessProfile = this.accessProfileFilter.map(ap => ap.id);
      }
      if (this.departmentFilter && this.departmentFilter.length) {
        filters.department = this.departmentFilter.map(d => d.id);
      }
      if (this.jobRoleFilter && this.jobRoleFilter.length) {
        filters.jobRole = this.jobRoleFilter.map(jr => jr.id);
      }
      if (this.jobFunctionFilter && this.jobFunctionFilter.length) {
        filters.jobFunction = this.jobFunctionFilter.map(jf => jf.id);
      }
      if (this.nameFilter) {
        filters.term = this.nameFilter;
      }
      if (this.cpfFilter) {
        const cpf = this.cpfFilter;
        let cpfUnformatted = cpf.replace(/\D/g, '');
        filters.cpf = cpfUnformatted;
      }
      if (this.statusFilter) {
        filters.situation = this.statusFilter.value;
      }
      this.filters = filters;
    }
    *filterName() {
      yield (0, _emberConcurrency.timeout)(300);
      this.getFilters();
    }
    *filterCpf() {
      yield (0, _emberConcurrency.timeout)(300);
      this.getFilters();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "accessProfileFilter", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "departmentFilter", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "jobFunctionFilter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "openFilter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "nameFilter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "statusFilter", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "cpfFilter", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "jobRoleFilter", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getFilters", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "getFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterName", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "filterName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterCpf", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "filterCpf"), _class.prototype)), _class));
  _exports.default = RegistryGeneralUserController;
});