define("account-talentrh/pods/registry/general/user/item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    userAccountIsBlocked: null,
    tagName: 'li',
    classNames: ['list-group-item'],
    route: 'registry.general.user.show',
    model: Ember.computed.alias('user.id'),
    status: Ember.computed('user.{active,away}', 'userAccountIsBlocked', function () {
      if (!this.user.active) {
        return 'Inativo';
      }
      if (this.user.away) {
        return 'Afastado';
      }
      if (this.userAccountIsBlocked) {
        return 'Bloqueado';
      }
      return '';
    }),
    statusClass: Ember.computed('user.{active,away}', 'userAccountIsBlocked', function () {
      if (!this.user.active) {
        return 'badge-danger';
      }
      if (this.user.away) {
        return 'border border-warning text-warning';
      }
      if (this.userAccountIsBlocked) {
        return 'border border-danger text-danger';
      }
      return '';
    }),
    getUserAccountIsBlocked: (0, _emberConcurrency.task)(function* () {
      try {
        const {
          blocked
        } = yield this.ajax.request(`userAccount/listUserAccountIsBlocked/${this.user.id}`);
        Ember.set(this, 'userAccountIsBlocked', blocked);
      } catch (e) {
        console.log(e);
      }
    }).drop()
  });
  _exports.default = _default;
});