define("account-talentrh/pods/registry/general/user/edit/corporate/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    permission: Ember.inject.service(),
    ajax: Ember.inject.service(),
    userAccountIsBlocked: null,
    user: Ember.computed.alias('model'),
    userMeta: Ember.computed.alias('loadUserMeta.lastSuccessful.value'),
    showUserCompanyBirthdayModal: false,
    query: Ember.computed('user.id', function () {
      return {
        excludeId: this.user.id
      };
    }),
    loadUserMeta: (0, _emberConcurrency.task)(function* () {
      try {
        return yield this.store.queryRecord('user-meta', {
          user: this.user.id
        });
      } catch (e) {
        return yield this.store.createRecord('user-meta', {
          user: this.user.id
        });
      }
    }).on('didInsertElement').drop(),
    getUserAccountIsBlocked: (0, _emberConcurrency.task)(function* () {
      try {
        const {
          blocked
        } = yield this.ajax.request(`userAccount/getUserAccountIsBlocked/${this.user.cpf}`);
        Ember.set(this, 'userAccountIsBlocked', blocked);
      } catch (e) {
        console.log(e);
      }
    }).drop(),
    updateUserBlockStatus: (0, _emberConcurrency.task)(function* (value) {
      try {
        value = !value;
        const {
          blocked
        } = yield this.ajax.post(`userAccount/updateStatusUserAccount/${this.user.id}`, {
          data: {
            blocked: value
          }
        });
        Ember.set(this, 'userAccountIsBlocked', blocked);
      } catch (e) {
        console.log(e);
      }
    }).restartable(),
    actions: {
      toggleHasNoLeader() {
        const toggle = !this.user.hasNoLeader;
        this.user.hasNoLeader = toggle;
        if (toggle) {
          this.user.appraisedBy = null;
        }
      }
    }
  });
  _exports.default = _default;
});