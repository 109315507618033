define("account-talentrh/models/goal", ["exports", "@ember-data/model", "ember-cp-validations", "moment"], function (_exports, _model, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar um título'
    }),
    unit: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar uma unidade de medida'
    }), (0, _emberCpValidations.validator)('inclusion', {
      in: ['numeric', 'percentage', 'monetary', 'hours', 'qualitative'],
      message: 'É necessário informar uma unidade de medida entre os valores aceitos'
    })],
    value: (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['unit', 'value', 'unitType'],
      validate(value, options, model) {
        const unit = model.unit;
        const unitType = model.unitType;
        if (value || unit === 'qualitative' || unitType === 'between') {
          return true;
        }
        if (!unit || unit === 'monetary') {
          return 'É necessário informar um valor';
        } else if (unit === 'percentage') {
          return 'É necessário informar um percentual';
        } else if (unit === 'hours') {
          return 'É necessário informar as horas';
        } else if (unit === 'numeric') {
          return 'É necessário informar uma quantidade';
        }
      }
    }),
    minValue: (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['maxValue'],
      validate(value, options, model) {
        const maxValue = model.maxValue;
        const minValue = value;
        if (maxValue && minValue) {
          return Number(maxValue) > Number(minValue) ? true : 'O valor minimo deve ser menor que o valor maximo';
        }
        return true;
      }
    }),
    startAt: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar uma data de início'
    }), (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['startAt', 'endAt'],
      validate(value, options, model) {
        const endAt = model.endAt;
        return (0, _moment.default)(value).isSameOrBefore(endAt) || 'A data inicial precisar ser anterior ou igual a data de encerramento';
      }
    })],
    endAt: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar uma data de encerramento'
    }),
    unitType: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar o tipo de unidade'
    }), (0, _emberCpValidations.validator)('inclusion', {
      in: ['greaterThanOrEqual', 'lessThanOrEqual', 'greaterThan', 'lessThan', 'between', 'qualitative'],
      message: 'É necessário informar um tipo de unidade de medida entre os valores aceitos'
    })],
    goalReplicationValue: (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['goalReplicationValue', 'goalReplicationType', 'startAt', 'endAt'],
      validate(value, options, model) {
        const goalReplicationType = model.goalReplicationType;
        let startAt = model.startAt;
        let endAt = model.endAt;
        let startAtAux = null;
        if (goalReplicationType === 'monthly') {
          if (!value) {
            return 'É necessário informar a cada quantos meses a meta será replicada';
          } else {
            let lastDayStartMonth = new Date(startAt.getFullYear(), startAt.getMonth() + 1, 0).getDate();
            let startAtMont = startAt.getMonth() + parseInt(value) + 1;
            let startAtYear = startAt.getFullYear();
            if (lastDayStartMonth === startAt.getDate()) {
              let day = new Date(startAt.getFullYear(), startAt.getMonth() + parseInt(value) + 1, 0).getDate();
              startAtAux = new Date(startAtYear, startAtMont - 1, day);
            } else if (startAt.getDate() > 28 && startAtMont === 2) {
              let day = new Date(startAt.getFullYear(), startAt.getMonth() + parseInt(value) + 1, 0).getDate();
              startAtAux = new Date(startAtYear, startAtMont - 1, day);
            } else {
              startAtAux = new Date(startAtYear, startAt.getMonth() + parseInt(value), startAt.getDate());
            }
            if (startAtAux.getTime() < endAt.getTime()) {
              return ' Não é possível replicar a meta dentro da sua vigência. Altere as datas de início ou encerramento ou a quantidade de meses para ser replicada.';
            }
            if (startAtAux.getTime() === endAt.getTime()) {
              return 'Não é possível replicar a meta onde  o dia de início é igual o dia de encerramento, pois a replica ficará com a data inicial igual a do encerramento desta meta. Exemplo: 10/02/2021 a 10/03/2021.  Altere o dia de início ou final da meta  seguindo o exemplo: 10/02/2021 a 09/03/2021.';
            }
          }
          return true;
        } else {
          return true;
        }
      }
    })
  });
  let Goal = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('date'), _dec13 = (0, _model.attr)('date'), _dec14 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec15 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec16 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec17 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec18 = (0, _model.belongsTo)('company'), _dec19 = (0, _model.hasMany)('branch', {
    inverse: 'goals'
  }), _dec20 = (0, _model.hasMany)('department', {
    inverse: 'goals'
  }), _dec21 = (0, _model.hasMany)('job-role', {
    inverse: 'goals'
  }), _dec22 = (0, _model.hasMany)('jobFunctions', {
    inverse: 'goals'
  }), _dec23 = (0, _model.hasMany)('user', {
    inverse: 'goals'
  }), _dec24 = (0, _model.hasMany)('user', {
    inverse: 'exclusiveGoals'
  }), _dec25 = Ember.computed('unit'), _dec26 = Ember.computed('unit'), _dec27 = Ember.computed('unitType', 'unit'), _dec28 = Ember.computed('value', 'unit'), _dec29 = Ember.computed('minValue', 'unit'), _dec30 = Ember.computed('maxValue', 'unit'), (_class = class Goal extends _model.default.extend(Validations) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "title", _descriptor, this);
      _initializerDefineProperty(this, "description", _descriptor2, this);
      _initializerDefineProperty(this, "unit", _descriptor3, this);
      _initializerDefineProperty(this, "unitType", _descriptor4, this);
      _initializerDefineProperty(this, "informationSource", _descriptor5, this);
      _initializerDefineProperty(this, "value", _descriptor6, this);
      _initializerDefineProperty(this, "minValue", _descriptor7, this);
      _initializerDefineProperty(this, "maxValue", _descriptor8, this);
      _initializerDefineProperty(this, "uniqueIdentifier", _descriptor9, this);
      _initializerDefineProperty(this, "goalReplicationType", _descriptor10, this);
      _initializerDefineProperty(this, "goalReplicationValue", _descriptor11, this);
      _initializerDefineProperty(this, "startAt", _descriptor12, this);
      _initializerDefineProperty(this, "endAt", _descriptor13, this);
      _initializerDefineProperty(this, "active", _descriptor14, this);
      _initializerDefineProperty(this, "isReplicated", _descriptor15, this);
      _initializerDefineProperty(this, "useAggregatedRelations", _descriptor16, this);
      _initializerDefineProperty(this, "showGoalToEmployee", _descriptor17, this);
      _initializerDefineProperty(this, "company", _descriptor18, this);
      _initializerDefineProperty(this, "branches", _descriptor19, this);
      _initializerDefineProperty(this, "departments", _descriptor20, this);
      _initializerDefineProperty(this, "jobRoles", _descriptor21, this);
      _initializerDefineProperty(this, "jobFunctions", _descriptor22, this);
      _initializerDefineProperty(this, "users", _descriptor23, this);
      _initializerDefineProperty(this, "exclusiveUsers", _descriptor24, this);
    }
    get unitDescription() {
      if (this.unit === 'numeric') return 'Numérica';
      if (this.unit === 'percentage') return 'Porcentagem';
      if (this.unit === 'monetary') return 'Monetária';
      if (this.unit === 'hours') return 'Horas';
      if (this.unit === 'qualitative') return 'Qualitativa';
      return "Não Informado";
    }
    get valueLabel() {
      if (this.unit === 'numeric') return 'Quantidade';
      if (this.unit === 'percentage') return 'Percentual';
      if (this.unit === 'monetary') return 'Valor';
      if (this.unit === 'hours') return 'Horas';
      return "Valor";
    }
    get unitTypeDescription() {
      if (this.unitType === 'greaterThanOrEqual') return 'Maior ou igual';
      if (this.unitType === 'lessThanOrEqual') return 'Menor ou igual';
      if (this.unitType === 'greaterThan') return 'Maior que';
      if (this.unitType === 'lessThan') return 'Menor que';
      if (this.unitType === 'between') return 'Entre';
      if (this.unitType === 'qualitative') return 'Atingida ou Não';
      return "Não informado";
    }
    _formatarValor(value, unit) {
      if (unit === 'percentage') {
        return Intl.NumberFormat('pt-br', {
          style: 'percent',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(value);
      }
      if (unit === 'monetary') {
        return Intl.NumberFormat('pt-br', {
          currency: 'BRL',
          style: 'currency',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(value);
      }
      if (unit === 'qualitative') {
        return 'Atingida';
      }
      if (unit === 'hours') {
        let str = '';
        let duration = _moment.default.duration(value, 'minutes');
        let hours = parseInt(duration.as('hours'));
        if (hours) {
          str += `${hours}h `;
        }
        if (duration.get('minutes')) {
          str += `${duration.get('minutes')}min`;
        }
        if (!str) {
          return 'Não informado';
        }
        return str;
      }
      return value;
    }
    get valueFormatted() {
      return this._formatarValor(this.value, this.unit);
    }
    get minValueFormatted() {
      return this._formatarValor(this.minValue, this.unit);
    }
    get maxValueFormatted() {
      return this._formatarValor(this.maxValue, this.unit);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "unit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "unitType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "informationSource", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "minValue", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "maxValue", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "uniqueIdentifier", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "goalReplicationType", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "goalReplicationValue", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "startAt", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "endAt", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "active", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isReplicated", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "useAggregatedRelations", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "showGoalToEmployee", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "branches", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "departments", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "jobRoles", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "jobFunctions", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "exclusiveUsers", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "unitDescription", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "unitDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "valueLabel", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "valueLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unitTypeDescription", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "unitTypeDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "valueFormatted", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "valueFormatted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "minValueFormatted", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "minValueFormatted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "maxValueFormatted", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "maxValueFormatted"), _class.prototype)), _class));
  _exports.default = Goal;
});